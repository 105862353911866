<template>
<div class="card">
  <div class="card-body">
    <p class="bold-weight mb-3">Ihre aktuelle Vorauswahl:</p>
    <table class="mb-3 table-steps">
      <tbody>
        <tr v-for="step in steps" :key="step.id">
          <td>{{ step.name }}:</td>
          <td>
            <i class="fas fa-pencil"></i>
            <a href="#" @click.prevent="changeCurrentStep(step.id)" class="step-edit">
              {{ step.selectedLabel }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <PriceBox />
  </div>
</div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import PriceBox from '~/pages/configurator/common/priceBox'
export default {
  components: {
    PriceBox
  },
  setup () {
    const store = useStore()
    // computed
    const steps = computed(() => {
      return store.getters['configurator/navigation']
    })
    // actions
    function changeCurrentStep (id) {
      store.dispatch('configurator/setCurrentStep', id)
    }
    return {
      steps,
      changeCurrentStep
    }
  }
}
</script>
