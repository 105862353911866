<template>
<div class="total">
  <div class="total__box">
    <div class="total-cost">
      <div class="total-cost__item flex-shrink-0">
        <div class="title-secondary bold-weight">Aktueller Preis:</div>
          <span class="text-ink">(inkl. 19% MwSt. zzgl. Versandkosten)</span>
        </div>
        <div v-if="stepDiscount > 0" class="total-cost__item text-end">
          <span class="badge bg-danger">-{{ stepDiscount }}%</span>
          <div class="total-price total-price--discount">{{ $filters.currency(totalPrice) }}</div>
          <div v-if="oldPrice > 0" class="total-price total-price--old mt-1">{{ $filters.currency(oldPrice) }}</div>
        </div>
        <div v-else-if="oldPrice > 0" class="total-cost__item text-end">
          <div class="total-price total-price--discount">{{ $filters.currency(totalPrice) }}</div>
          <div class="total-price total-price--old mt-1">{{ $filters.currency(oldPrice) }}</div>
        </div>
        <div v-else class="total-cost__item text-end">
          <div class="total-price total-price--discount">{{ $filters.currency(totalPrice) }}</div>
        </div>
      </div>
      <div class="total__box-cart">
        <a class="btn btn-outline-primary" data-bs-toggle="modal" href="#requestModal">Unverbindliche Anfrage</a>
        <a class="btn btn-primary" @click="addToCart">In den Warenkorb</a>
      </div>
      <div class="text-center">
        <a href="#linkModal" class="fs-6 text-muted" data-bs-toggle="modal"><i class="fas fa-save"></i> Konfiguration speichern</a>
      </div>
  </div>
</div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  setup () {
    const store = useStore()
    const router = useRouter()
    const stepDiscount = computed(() => {
      return store.getters['configurator/discount']
    })
    const price = computed(() => {
      return store.getters['configurator/price']
    })
    const totalPrice = computed(() => {
      if (price.value.discounted > 0) {
        return price.value.discounted
      }
      return Math.ceil(price.value.total)
    })
    const oldPrice = computed(() => {
      if (typeof price.value.old_price !== 'undefined') {
        if (price.value.old_price === Math.ceil(price.value.total)) {
          return price.value.old_price
        } else {
          return price.value.old_price
        }
      } else {
        return 0
      }
    })
    function addToCart () {
      const summary = store.getters['configurator/summary']
      store.dispatch('checkout/addItem', summary)
      // redirect to cart
      router.push({ name: 'cart' })
    }
    return {
      stepDiscount,
      totalPrice,
      oldPrice,
      addToCart
    }
  }
}
</script>
